<template>
	<div class="council">
		<SubHeader
			title="Annual Player Ranking"
			subtitle="年間プレイヤーランキング"
		/>

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<ul class="nav nav-pills mb-3" id="myTab" role="tablist">
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);">
								2023年度
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/annual_player_ranking2022">
								2022年度
							</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="/annual_player_ranking2019">
								2019年度
							</a>
						</li>
					</ul>

					<div class="table-responsive mb-5">
						<table class="table table-striped">
							<thead class="thead-dark">
								<tr>
									<th scope="col" class="text-nowrap">#</th>
									<th scope="col" class="text-nowrap">Team</th>
									<th scope="col" class="text-nowrap">Player</th>
									<th scope="col" class="text-nowrap">前期</th>
									<th scope="col" class="text-nowrap">中期</th>
									<th scope="col" class="text-nowrap">後期</th>
									<th scope="col" class="text-nowrap">ODO大会</th>
									<th scope="col" class="text-nowrap">Total Pts</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(player, index) in list" :key="'rank' + index">
									<td class="text-nowrap">{{ player.rank }}</td>
									<td class="text-nowrap">{{ player.team_name }}</td>
									<td class="text-nowrap">{{ player.player_name }}</td>
									<td class="text-nowrap">{{ player["前期"] }}</td>
									<td class="text-nowrap">{{ player["中期"] }}</td>
									<td class="text-nowrap">{{ player["後期"] }}</td>
									<td class="text-nowrap">{{ player.extra_point }}</td>
									<td class="text-nowrap">{{ player.total_point }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import SubHeader from "@/components/common/SubHeader.vue";
//import Schedule from "@/components/common/Schedule.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading);

export default {
	name: "schedule",
	components: {
		SubHeader,
	},
	data() {
		return {
			isLoading: true,
			list: [],
			group_id: null,
		};
	},
	mounted: function () {
		this.getPlayerRanking();
	},
	methods: {
		getPlayerRanking: function () {
			this.isLoading = true;
			let loader = this.$loading.show({
				// Optional parameters
				container: this.fullPage ? null : this.$refs.formContainer,
				canCancel: false,
				color: this.$store.state.primary_color,
				backgroundColor: "#666",
			});
			let params = {
				group_id: this.group_id,
			};
			this.axios
				.get("https://admin.darts-l.com/api/odo/2023/annual_player_ranking", {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data;
					// simulate AJAX
					setTimeout(() => {
						loader.hide();
						this.isLoading = false;
					}, 500);
				});
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.6rem;
	margin-bottom: 1rem;
}
.container {
	padding: 4rem 1rem 0 1rem;
	text-align: left;
}
.table-bordered {
	border: 1px solid #999;
	td,
	th {
		border: 1px solid #999;
		vertical-align: middle;
	}
}
.list-group-item {
	border: none;
	background-color: transparent;
	padding: 0 1.25rem;
	.badge {
		font-size: 1.2rem;
	}
}
</style>
